import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,DateInput,
  SelectInput,
  required, ImageInput, ImageField,
  BooleanInput,
} from "react-admin";
import { useStyles } from "../../formStyles";
import { FormToolbar } from "../../common/FormToolbar";
import RichTextInput from 'ra-input-rich-text';

export const ActivityCreate = (props) => {
  const classes = useStyles({});
  return (
    <Create title="Event Create" {...props}>
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
        <TextInput source="name" fullWidth={true} validate={[required()]} />
        <TextInput source="category" fullWidth={true} validate={[required()]} />
        <RichTextInput source="shortText" fullWidth={true} />
        <RichTextInput toolbar={[
          [{ 'header': [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['image', 'link', 'video'],
          ['clean']
        ]}
          source="description" fullWidth={true} />
        <RichTextInput source="address" fullWidth={true} />
        <RichTextInput source="contact" fullWidth={true} />
        <RichTextInput source="resources" fullWidth={true} />
        <DateInput source="startDate" label="Start Date" validate={[required()]} fullWidth={true}  formClassName={classes.first_inline_input} />
        <DateInput source="endDate" label="End Date" validate={[required()]} fullWidth={true}  formClassName={classes.last_inline_input} />

        <ImageInput source="imageUrl" fullWidth={true}
          label="Logo" accept="image/*" className="image" >
          <ImageField source="imageUrl" title="Image" />
        </ImageInput>
        <BooleanInput initialValue={false} source="isConsentForm" label="Consent Form Required" />
        <RichTextInput source="conscentHeader" fullWidth={true} initialValue={""}/>
        <RichTextInput source="conscentFooter" fullWidth={true} initialValue={""}/>
        <RichTextInput source="termAndConditions" fullWidth={true} initialValue={""}/>
        <RichTextInput source="emailNotificationContents" fullWidth={true} initialValue={""} toolbar={[
          [{ 'header': [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['image', 'link', 'video'],
          ['clean']
        ]}/>
        	

      </SimpleForm>
    </Create>
  );
};



