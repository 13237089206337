import React, { useState, useEffect } from "react";
import { Admin, Resource } from "react-admin";
import { ListGuesser, EditGuesser } from "react-admin";
import { APIUrl } from "./common/apiClient";
import VSDataProvider from "./providers/VSDataProvider";

import polyglotI18nProvider from "ra-i18n-polyglot";

import "./App.css";

import authProvider from "./authProvider";
import themeReducer from "./themeReducer";
import { Login, Layout } from "./layout";
import { Dashboard } from "./dashboard";
import customRoutes from "./routes";
import englishMessages from "./i18n/en";

import roletypes from './screens/roletypes';
import district from "./screens/district";
import state from "./screens/state";
import city from "./screens/city";
import countries from "./screens/country";
import areas from './screens/areas';
import industries from './screens/industries';
import services from "./screens/services";
import pageContent from './screens/page-content';
import teams from './screens/teams';
import events from './screens/events';
import blogPosts from './screens/BlogPosts';
import sitepages from "./screens/site-pages";

import users from './screens/users';
import employee from './screens/employee';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "fr") {
    return import("./i18n/fr").then((messages) => messages.default);
  }

  // Always fallback on english
  return englishMessages;
}, "en");
//console.log(process.env.REACT_APP_SERVER_URL, "==REACT_APP_SERVER_URL==");
const App = () => {
  const [dataProvider, setDataProvider] = useState({});

  useEffect(() => {
    let provider = VSDataProvider(APIUrl);
    setDataProvider(provider);

  }, []);

  return (
    <>
      <Admin
        title=""
        dataProvider={dataProvider}
        customReducers={{ theme: themeReducer }}
        customRoutes={customRoutes}
        authProvider={authProvider}
        dashboard={Dashboard}
        loginPage={Login}
        layout={Layout}
        i18nProvider={i18nProvider}
      >
        <Resource title="Area" name="areas" {...areas} />
        <Resource title="User" name="users" {...users} />
        <Resource title="Employee" name="employees" {...employee} />
        <Resource title="Role Type" name="role-types" {...roletypes} />
        <Resource title="Country" name="countries" {...countries} />
        <Resource title="City" name="cities" {...city} />
        <Resource title="States" name="states" {...state} />
        <Resource name="districts" {...district} />
        <Resource name="content-page-configs" {...pageContent} />  
        <Resource name="industries" {...industries} />  
        <Resource name="services" {...services} />  
        <Resource name="teams" {...teams} />  
        <Resource name="events" {...events} />  
        <Resource name="blog-posts" {...blogPosts} />  
        <Resource name="site-pages" {...sitepages}/>
      </Admin>
      <ToastContainer />
    </>
  );
};

export default App;
